// TODO: dodelat jazykovou mutaci nize

export function LocalDate(date: string, addDays?: number, hideYear?: boolean, nameOfDay?: boolean) {
  const localDate = new Date(date)

  const options = {
    weekday: nameOfDay ? 'long' : undefined,
    year: hideYear ? undefined : 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  if (addDays) {
    localDate.setDate(localDate.getDate() + addDays)
  }

  return localDate.toLocaleDateString('cs', options)
}

export function LocalTime(date: string) {
  return new Date(date).toLocaleTimeString('cs', { timeStyle: 'short' })
}
